import React, { ReactElement, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Typography, Input, Form, Alert, Radio, Space } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import { CancelToken } from 'axios'
import { useStateIfMounted } from 'use-state-if-mounted'
import { facilityUnitAction } from '../../../redux/actions/get-facility-unit'
import { initializePaymentAction } from '../../../redux/actions/initialize-payment'
import { GET_USER_TYPE } from '../../../redux/types'

import './WhatToPayFor.styles.scss'
import { useMediaQueries } from '../../../hooks'
import { ModalContentType } from '../../../models/modal.model'
import CustomModal from '../../../components/CustomModal'
import ModalContent from '../../Dashboard/ModalContent'
import { IUser } from '../../../models/user.model'
import { AmountInputType, ILineItem } from '../../../models/line-item.model'
import {
  ICalculateLineItemOptions,
  IFacilityUnit,
  IResidentUserRole,
} from '../../../models/facility-unit.model'
import {
  IInitializePayment,
  IInitializePaymentOption,
} from '../../../models/initialize-payment.model'
import {
  calculateLineItemAction,
  getUserAction,
  setStepLevel as setStepLevelFnc,
  setEnergyCreditsCheckBox as setEnergyCreditsCheckBoxFnc,
  setCommunityFeeCheckBox as setCommunityFeeCheckBoxFnc,
  setEnergyCreditFee as setEnergyCreditFeeFnc,
  setCommunityFee as setCommunityFeeFnc,
  setHowToPay as setHowToPayFnc,
  setProcessingFee as setProcessingFeeFnc,
} from '../../../redux/actions'
import { formatCurrency } from '../../../utils/formatMoney'
import CheckBox from '../../../components/CheckBox/CheckBox'
import WhatToPayForLoader from '../../../components/Loader/WhatToPayForLoader'
import { FLUTTERWAVE_LIMIT } from '../../../utils/constant'
import EnergyCreditSummary from '../../../components/EnergyCreditSummary/EnergyCreditSummary'

interface IWhatToPayForProps {
  setProcessingFee: any
  setStepLevel: any
  setEnergyCreditsCheckBox: any
  setCommunityFeeCheckBox: any
  setEnergyCreditFee: any
  setCommunityFee: any
  setHowToPay: any
  lineItems: any
  facilityUnitLoading: boolean
  facilityUnit: any
  initializePaymentDetails: IInitializePayment
  userDataLoading: boolean
  stepLevel: number
  communityFee: number
  processingFee: number
  energyCreditFee: number
  energyCreditsCheckBoxState: boolean
  communityFeeCheckBoxState: boolean
  lineItemLoading: boolean
  errorFromReducers: any
  showPaymentModalFromTest?: boolean
}

const WhatToPayFor = ({
  setStepLevel,
  setEnergyCreditsCheckBox,
  setCommunityFeeCheckBox,
  setEnergyCreditFee,
  setCommunityFee,
  setProcessingFee,
  lineItems,
  facilityUnitLoading,
  facilityUnit,
  initializePaymentDetails,
  userDataLoading,
  stepLevel,
  communityFee,
  processingFee,
  energyCreditFee,
  energyCreditsCheckBoxState,
  communityFeeCheckBoxState,
  lineItemLoading,
  errorFromReducers,
  setHowToPay,
  showPaymentModalFromTest,
}: IWhatToPayForProps): ReactElement => {
  const [userDetails, setUserDetails] = useStateIfMounted({})
  const [reload, setReload] = useState(true)
  const sumTotalIncent = () => (energyCreditFee + communityFee) * 100

  const [selectedCommunityFee, setSelectedCommunityFee] = useState('')

  const handleCheckCommunityFee = (check: boolean) => {
    if (!check) {
      setSelectedCommunityFee('')
      setCommunityFee(0)
    }
    setCommunityFeeCheckBox(check)
  }
  let currentMeterState = facilityUnit?.currentMeterState
  const currentRate = facilityUnit.currentBillingPlan?.tariffValues?.rate

  useEffect(() => {
    setTimeout(() => {
      currentMeterState = facilityUnit?.currentMeterState
      setSelectedCommunityFee('')
      setCommunityFee(0)
      setReload(false)
    }, 2000)
    setReload(true)
  }, [facilityUnit])

  const chargedFeesDueAmountCents = currentMeterState?.chargedFeesDueAmountCents
  const pastAmountDue = currentMeterState?.chargedFeesOverdueAmountCents
  const totalAmountDue = Math.max(0, chargedFeesDueAmountCents + pastAmountDue)

  const { Text, Title } = Typography
  const { isDashboardMobile, isMobileOrTablet } = useMediaQueries()
  const { GET_USER_SUCCESS, GET_USER_FAILURE } = GET_USER_TYPE

  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState<ModalContentType>({
    title: '',
    body: [],
  })

  const handleSeleteCommunityFee = (event: any) => {
    // the amount in kobo
    setSelectedCommunityFee(event?.target?.value)
    switch (event?.target?.value) {
      case 'pastAmountDue':
        setCommunityFee(pastAmountDue / 100)
        break
      case 'totalAmountDue':
        setCommunityFee(totalAmountDue / 100)
        break
      default:
        setCommunityFee(0)
    }
  }

  useEffect(() => {
    setHowToPay('')
  }, [communityFeeCheckBoxState, energyCreditsCheckBoxState])

  const handleUpdateEnergyCreditFee = (amountInNaira: number) => {
    // the amount is in naira
    setEnergyCreditFee(amountInNaira)
  }
  return (
    <>
      <CustomModal
        showModal={showModal}
        onOk={() => {
          setShowModal(false)
        }}
        onCancel={() => {
          setShowModal(false)
        }}
        style={
          !isMobileOrTablet
            ? {
                marginLeft: '30%',
                borderRadius: '8px',
                padding: '24px 5px 50px',
                marginTop: '5%',
              }
            : {
                marginLeft: '5%',
              }
        }
      >
        <ModalContent title={modalContent.title} body={modalContent.body} />
      </CustomModal>

      <Title
        level={4}
        className="header-text"
        data-testid="payee-title-text"
        id="payee-details-header"
      >
        What would you like to pay for?
      </Title>
      <Title
        level={5}
        className="sub-heading-text_"
        data-testid="payee-title-text"
        id="payee-details-header"
      >
        Select the box(es) that apply.
      </Title>

      {reload ? (
        <WhatToPayForLoader />
      ) : (
        <Form layout="vertical" className="form-div">
          <br />
          <br />
          <Input.Group size="large">
            <Form.Item>
              <Row gutter={1} justify="start">
                <Col xs={12} sm={12} md={{ span: 12 }} lg={7} xl={7}>
                  <Row align="middle">
                    <div style={{ maxWidth: isMobileOrTablet ? '90%' : 'auto' }}>
                      <CheckBox
                        name="Energy credits"
                        state={energyCreditsCheckBoxState}
                        disabled={false}
                        onClick={(check: boolean) => setEnergyCreditsCheckBox(check)}
                      />
                    </div>
                    <span>
                      <InfoCircleOutlined
                        onClick={() => {
                          return (
                            setShowModal(true),
                            setModalContent({
                              title: 'What are Energy Credits?',
                              body: [
                                'Energy credits are what you purchase and add to your account so that you can use energy.',
                                'Purchasing energy credits does not automatically apply to Community or Facility Management Fees. You’ll need to select or pay for fees separately.',
                                'If you have questions or comments about energy credits, please contact your power or facility management company.',
                              ],
                            })
                          )
                        }}
                      />
                    </span>
                  </Row>
                </Col>

                <Col
                  span={7}
                  offset={isDashboardMobile ? 4 : 3}
                  className={!energyCreditsCheckBoxState ? 'disabled-shade' : ''}
                  id="energy-credit"
                >
                  <NumberFormat
                    disabled={!energyCreditsCheckBoxState}
                    className="ant-input"
                    style={{ fontFamily: 'sans-serif' }}
                    thousandSeparator
                    fixedDecimalScale
                    prefix={energyCreditsCheckBoxState ? '₦ ' : ''}
                    decimalScale={2}
                    value={energyCreditsCheckBoxState ? energyCreditFee || null : ''}
                    id="energy-credit-input"
                    onValueChange={(event) => handleUpdateEnergyCreditFee(event.floatValue || 0)}
                    data-testid="energy-credit-input"
                  />
                </Col>
              </Row>
            </Form.Item>
          </Input.Group>
          {energyCreditsCheckBoxState && (
            <EnergyCreditSummary bold currentRate={currentRate} energyCreditFee={energyCreditFee} />
          )}
          <br />
          <Input.Group size="large">
            <Form.Item>
              <Row gutter={1} justify="start">
                <Col xs={12} sm={12} md={{ span: 12 }} lg={7} xl={7}>
                  <Row align="middle">
                    <div style={{ maxWidth: isMobileOrTablet ? '90%' : 'auto' }}>
                      <CheckBox
                        name="Estate / community fees"
                        state={communityFeeCheckBoxState}
                        disabled={false}
                        onClick={(check: boolean) => handleCheckCommunityFee(check)}
                      />
                    </div>
                    <span>
                      <InfoCircleOutlined
                        onClick={() => {
                          return (
                            setShowModal(true),
                            setModalContent({
                              title: 'What are Estate/Community Management Fees?',
                              body: [
                                'Community and Facility Management Fees are fees charged by your power or facility management company.',
                                'You can choose to pay the amount of fees due, past due balances or opt to not make a payment towards fees at this time.',
                                'If you have questions or comments about the fees, please contact your power or facility management company.',
                              ],
                            })
                          )
                        }}
                      />
                    </span>
                  </Row>
                </Col>

                <Col
                  span={7}
                  offset={isDashboardMobile ? 4 : 3}
                  className="disabled-shade"
                  id="community-fee"
                >
                  <NumberFormat
                    disabled
                    className="ant-input"
                    style={{ fontFamily: 'sans-serif' }}
                    thousandSeparator
                    fixedDecimalScale
                    prefix="₦ "
                    decimalScale={2}
                    value={communityFee}
                    id="energy-credit-input"
                    data-testid="community-fee-input"
                  />
                </Col>
              </Row>
            </Form.Item>
          </Input.Group>

          <Row gutter={1}>
            <Col xs={24} sm={24} md={{ span: 12 }} lg={12} xl={12}>
              <Radio.Group
                value={selectedCommunityFee}
                onChange={(event) => handleSeleteCommunityFee(event)}
                disabled={!communityFeeCheckBoxState}
                className={`radio-group-box ${!communityFeeCheckBoxState && 'disabled-shade'}`}
              >
                <Space direction="vertical">
                  <Radio className="radio-style-box" value="totalAmountDue">
                    {`All Due (${formatCurrency(totalAmountDue)})`}
                  </Radio>
                  <Radio className="radio-style-box" value="pastAmountDue">
                    {`Past Due Only (${formatCurrency(pastAmountDue)})`}
                  </Radio>
                </Space>
              </Radio.Group>
            </Col>
          </Row>

          <Row gutter={1} justify="start">
            <Col xs={12} sm={12} md={{ span: 12 }} lg={6} xl={6}>
              <Space direction="vertical" align="end" className="sub-total-block">
                <Text className="sub-total-text">Sub-Total</Text>
                <Text className="sub-total-sub-text">(excludes processing fees)</Text>
              </Space>
            </Col>
            <Col span={8} offset={isDashboardMobile ? 3 : 3} className="sub-total">
              <Space direction="vertical" align="end" className="sub-total-block">
                <Text className="sub-total-value">
                  {formatCurrency((communityFee + energyCreditFee) * 100)}
                </Text>
              </Space>
            </Col>
          </Row>

          {sumTotalIncent() / 100 > FLUTTERWAVE_LIMIT / 100 && energyCreditsCheckBoxState && (
            <Row gutter={30} justify="start">
              <Col
                xs={24}
                sm={24}
                md={{ span: 24 }}
                lg={18}
                xl={18}
                // offset={isDashboardMobile ? 1 : 12}
              >
                {' '}
                <Alert
                  showIcon={false}
                  type="error"
                  message={`The maximum transaction amount is ${formatCurrency(FLUTTERWAVE_LIMIT)}`}
                  banner
                  className="alert-error-div"
                />
              </Col>
            </Row>
          )}
        </Form>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch: any) => ({
  setProcessingFee: (processingFee: number) => dispatch(setProcessingFeeFnc(processingFee)),
  setStepLevel: (setStep: number) => dispatch(setStepLevelFnc(setStep)),
  setHowToPay: (howToPay: string) => dispatch(setHowToPayFnc(howToPay)),
  setEnergyCreditsCheckBox: (energyCreditsCheckBoxState: boolean) =>
    dispatch(setEnergyCreditsCheckBoxFnc(energyCreditsCheckBoxState)),
  setCommunityFeeCheckBox: (communityFeeeCheckBoxState: boolean) =>
    dispatch(setCommunityFeeCheckBoxFnc(communityFeeeCheckBoxState)),
  setEnergyCreditFee: (energyCreditFee: number) => dispatch(setEnergyCreditFeeFnc(energyCreditFee)),
  setCommunityFee: (communityFee: number) => dispatch(setCommunityFeeFnc(communityFee)),
})

const mapStateToProps = ({
  getUserReducer,
  setStepReducer,
  paymentValuesReducer,
  calculateLineItemReducer,
  getFacilityUnitReducer,
  initializePaymentReducer,
  errorReducer,
}: {
  getUserReducer: { userDetails: IUser; isLoading: boolean }
  setStepReducer: {
    stepLevel: number
    energyCreditsCheckBoxState: boolean
    communityFeeCheckBoxState: boolean
  }
  paymentValuesReducer: { communityFee: number; energyCreditFee: number; processingFee: number }
  calculateLineItemReducer: { lineItems: ILineItem; isLoading: boolean }
  getFacilityUnitReducer: { facilityUnit: IFacilityUnit; isLoading: boolean }
  initializePaymentReducer: { initializePaymentDetails: IInitializePayment; isLoading: boolean }
  errorReducer: { error: any; type: string }
}) => {
  const { userDetails, isLoading: userDataLoading } = getUserReducer
  const { stepLevel, energyCreditsCheckBoxState, communityFeeCheckBoxState } = setStepReducer
  const { communityFee, energyCreditFee, processingFee } = paymentValuesReducer
  const { lineItems, isLoading: lineItemLoading } = calculateLineItemReducer
  const { facilityUnit, isLoading: facilityUnitLoading } = getFacilityUnitReducer
  const { initializePaymentDetails, isLoading: initializePaymentLoading } = initializePaymentReducer
  const errorFromReducers = errorReducer

  return {
    userDetails,
    stepLevel,
    communityFee,
    energyCreditFee,
    processingFee,
    energyCreditsCheckBoxState,
    communityFeeCheckBoxState,
    lineItems,
    facilityUnit,
    facilityUnitLoading,
    initializePaymentDetails,
    initializePaymentLoading,
    userDataLoading,
    lineItemLoading,
    errorReducer,
    errorFromReducers,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WhatToPayFor)
